import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';
import { userService } from '@/_services/user.service.js';
import router from '@/router';
Vue.use(Vuex)

const storedAuth = localStorage.getItem('auth');
const state =  {  
  auth: (storedAuth ? JSON.parse(storedAuth) : null)
}

if (state.auth != null) {
  axios.interceptors.request.use(function (config) {          
    const token = state.auth.token;  
    config.headers.Authorization = 'Bearer ' + token;

    return config;
  });
}

export default new Vuex.Store({
  state: state,
  mutations: {
    loginSuccess(state, payload) {
      state.auth = payload;
      axios.interceptors.request.use(function (config) {
        const token = state.auth.token;        
        config.headers.Authorization = 'Bearer ' + token;
        return config;
      });
      router.push('/');
    },
    logout(state) {
      state.auth = null;
      localStorage.removeItem('auth');
      router.push('/');
      axios.interceptors.request.use(function (config) {
        config.headers.Authorization = null;
        return config;
      });
    }
  },
  actions: {
    async login({ commit }, token) {      
      const authModel = await userService.login(token);      
      commit("loginSuccess", authModel);
    },
    logout({ commit }) {
      commit("logout");
    },
  },
  getters: {
    user(state) {
      return state.auth != null ? state.auth.user : null;
    },
    token(state) {
      return state.auth != null ? state.auth.token : null;
    }
  },
  modules: {

  }
})

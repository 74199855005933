<template>
  <v-app id="test">
    <v-app-bar app color="primary" dark dense>
      <!-- <v-container class="py-0" fill-height> -->
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="./assets/OMG_tapper_trans.png"
          transition="scale-transition"
          width="40"
        />
      </div>
      <v-btn v-for="link in links" :key="link.title" v-bind:to="link.url" v-if="link.isAdmin == false || (user != null && user.isAdmin == true)" text>
        {{ link.title }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn href="https://id.twitch.tv/oauth2/authorize?client_id=aumtlo96tthapy7g2p73xnj1b9krtg&lang=en&login_type=login&response_type=token&redirect_uri=https://omgtaplounge.com&scope=user:read:email" v-if="user == null" text height="100%"> Login with Twitch </v-btn>
      <v-menu
        v-if="user != null"
        v-model="menu"
        :close-on-content-click="false"
        :nudge-width="200"
        offset-x
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn fab text v-bind="attrs" v-on="on">
            <v-avatar
              id="avatar"
              v-bind="attrs"
              v-on="on"
              ref="avatar"
              @click="menu = true"
              size="36px"
            >
              <img
                :src="user.twitchProfileImageUrl"
              />
            </v-avatar>
          </v-btn>
        </template>

        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <img
                  :src="user.twitchProfileImageUrl"
                  alt="Zaphire"
                />
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>{{user.twitchDisplayName}}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>
          <v-list dense nav>
            <v-list-item @click="logout" link>
              <v-list-item-icon>
                <v-icon>fas fa-sign-out-alt</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Logout</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>   
        </v-card>
      </v-menu>
    </v-app-bar>

    <v-main>
      <keep-alive include="Home">
        <router-view></router-view>
      </keep-alive>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  components: {},
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  mounted() {},
  data: () => ({
    fav: true,
    menu: false,
    message: false,
    hints: true,
    // items: [{ title: "Logout", icon: "fas fa-sign-out-alt" }],
    links: [
      { title: "Lounge", url: "/", isAdmin: false },
      // { title: "Merch Store", url: "/merch", isAdmin: false },
      { title: "Streamers", url: "/streamers", isAdmin: false },
      { title: "Admin", url: "/admin", isAdmin: true },
    ],
  }),
  methods: {
    logout() {
      this.$store.dispatch("logout");
    }
  }
};
</script>

<style scoped>
#test {
  background: url("./assets/game-background.png"),
    linear-gradient(
      140.6deg,
      rgba(0, 186, 255, 1) 11.2%,
      rgba(255, 0, 138, 1) 92.5%
    );
  /* background-image: linear-gradient( 109.6deg,  rgba(0,186,255,1) 11.2%, rgba(0,91,125,1) 92.5% ); */
  background-blend-mode: screen;
  /* background-color: var(--v-accent-lighten2); */
}
</style>


<template>
  <v-container>
    <v-btn href="https://id.twitch.tv/oauth2/authorize?client_id=aumtlo96tthapy7g2p73xnj1b9krtg&lang=en&login_type=login&response_type=token&redirect_uri=https://omgtaplounge.com&scope=user:read:email+channel:read:subscriptions" color="secondary"
        >Sync Twitch Subs<v-icon>fab fa-twitch</v-icon></v-btn
      >
    <v-card>
      <v-card-title>
        <v-col> Shop Items </v-col>
        <v-col>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
      </v-card-title>
      <v-card-text class="pa-0 ma-0">
        <v-data-table
          :headers="headers"
          :items="shopItems"
          :items-per-page="15"
          :search="search"
          class="elevation-1"
        >
          <template v-slot:item.actions="{ item }">
            <v-btn
              @click="
                editDialog = true;
                selectedItem = item;
              "
              >Edit</v-btn
            >
          </template>
        </v-data-table>      
      </v-card-text>
      <v-card-actions>
        <v-btn @click="addItem()">Add Item</v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="editDialog" persistent max-width="600px">
      <v-card>
        <v-card-title> Edit / Create Item </v-card-title>
        <v-card-text v-if="selectedItem != null">
          <v-row>
            <v-text-field
              v-model="selectedItem.name"
              label="Item Name"
            ></v-text-field>
          </v-row>
          <v-row>
            <v-textarea
              label="Item Description"
              v-model:value="selectedItem.description"
              rows="1"
            ></v-textarea>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                :items="itemTypes"
                label="Type"
                v-model="selectedItem.type"
              ></v-select>
            </v-col>
            <v-col>
              <v-select
                :items="itemSubTypes"
                label="Sub Type (AKA Twitch Game)"
                v-model="selectedItem.subType"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="selectedItem.cost"
                type="number"
                label="Cost"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="selectedItem.stock"
                type="number"
                label="Stock"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-textarea
              label="Streamer Message"
              v-model:value="selectedItem.announceMessage"
              rows="1"
              hint="{buyer} {streamer} {cost} {item} {stock}"
            ></v-textarea>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                v-model="selectedItem.announceChannels"
                :items="streamers"
                label="Target Streamer Channels"
                multiple
                chips
              ></v-select>
            </v-col>
            <v-col>
              <v-select
                v-model="selectedItem.requiredCards"
                :items="cards"
                label="Required Cards"
                multiple
                chips
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-switch
              v-model="selectedItem.isActive"
              label="IsActive"
            ></v-switch>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="saveOrAddItem()">Save</v-btn>
          <v-btn @click="editDialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { ShopApi } from "../_services/api_base/index";
import { Configuration } from "../_services/api_base/configuration";

export default {
  name: "Admin",
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  data() {
    return {
      shopItems: [],
      editDialog: false,
      selectedItem: null,
      search: "",
      itemTypes: ["bar", "challenge", "game"],
      itemSubTypes: ["", "Just Chatting", "Call of Duty: Warzone"],
      streamers: ["problematiclive", "zaphireza"],
      cards: ["visitor", "follower", "sub", "vip"],
      headers: [
        {
          text: "Name",
          value: "name",
        },
        { text: "Cost", value: "cost" },
        { text: "Stock", value: "stock" },
        { text: "Type", value: "type" },
        { text: "Sub Type", value: "subType" },
        { text: "Channels", value: "announceChannels" },
        { text: "Cards", value: "requiredCards" },
        { text: "Owner", value: "streamer.twitchDisplayName" },
        { text: "IsActive", value: "isActive" },
        { text: "Actions", value: "actions" },
      ],
    };
  },
  components: {},
  mounted() {
    this.loadShotItems();
  },
  methods: {
    loadShotItems() {
      const config = new Configuration();
      config.basePath = "https://www.api.omgtaplounge.com:5423";
      const shopApi = new ShopApi(config);

      let that = this;
      const result = shopApi
        .shopItemsTypeGet("admin")
        .then((result) => {

          result.data.forEach((row) => {
            if (!Array.isArray(row.announceChannels) && row.announceChannels != null) {
              var channels = row.announceChannels.split(',');
              row.announceChannels = channels;
            }
          });

          result.data.forEach((row) => {
            if (!Array.isArray(row.requiredCards) && row.requiredCards != null) {
              var cards = row.requiredCards.split(',');
              row.requiredCards = cards;
            }
          });


          that.shopItems = result.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addItem() {
      this.editDialog = true;
      this.selectedItem = {};
    },
    saveOrAddItem() {
      const config = new Configuration();
      config.basePath = "https://www.api.omgtaplounge.com:5423";
      const shopApi = new ShopApi(config);

      let channels = "";
      let cards = "";

      if (Array.isArray(this.selectedItem.announceChannels)) {
        for (let i = 0; i < this.selectedItem.announceChannels.length; i++) {
          if (channels != "") channels += ",";
          if (this.selectedItem.announceChannels[i] != "") {
          channels += this.selectedItem.announceChannels[i];
          }
        }
      } else {
        channels = this.selectedItem.announceChannels;
      }

      if (Array.isArray(this.selectedItem.requiredCards)) {
        for (let i = 0; i < this.selectedItem.requiredCards.length; i++) {
          if (cards != "") cards += ",";
          if (this.selectedItem.requiredCards[i] != "") {
            cards += this.selectedItem.requiredCards[i];
          }
        }
      } else {
        cards = this.selectedItem.requiredCards;
      }

      let updateObj = {
        name: this.selectedItem.name,
        description: this.selectedItem.description,
        cost: parseInt(this.selectedItem.cost),
        stock: parseInt(this.selectedItem.stock),
        announceChannels: channels,
        announceMessage: this.selectedItem.announceMessage,
        isActive: this.selectedItem.isActive,
        requiredCards: cards,
        type: this.selectedItem.type,
        subType: this.selectedItem.subType,
      };

      console.log(updateObj);

      if (this.selectedItem.id == null) {
        let that = this;
        const result = shopApi
          .shopItemsPost(updateObj)
          .then((result) => {
            this.loadShotItems();
            console.log(result);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        let that = this;
        //var test = JSON.parse(that.selectedItem);
        const result = shopApi
          .shopItemsItemIdPatch(that.selectedItem.id, updateObj)
          .then((result) => {
            this.loadShotItems();
            console.log(result);
          })
          .catch((err) => {
            console.log(err);
          });
      }

      this.editDialog = false;
    },
  },
};
</script>

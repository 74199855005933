import { UsersApi } from './api_base/index';
import { Configuration } from './api_base/index'

async function login(accessToken) {
    const config = new Configuration();
    config.basePath = 'https://www.api.omgtaplounge.com:5423';    
    // config.basePath = 'https://localhost:44345';    
    const userApi = new UsersApi(config);    

    const result = await userApi.usersLoginTwitchGet(accessToken);
    localStorage.setItem('auth', JSON.stringify(result.data));
    return result.data;
}

export const userService = {
    login    
};

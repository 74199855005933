<template>
  <v-row id="loginRow" align="center" justify="center">
    <div class="login">
      <v-btn href="https://id.twitch.tv/oauth2/authorize?client_id=aumtlo96tthapy7g2p73xnj1b9krtg&lang=en&login_type=login&response_type=token&redirect_uri=https://testing.omgtaplounge.com&scope=user:read:email" color="secondary"
        >Login with Twitch <v-icon>fab fa-twitch</v-icon></v-btn
      ><br/>
      <img src="../assets/OMG_tapper_trans.png" width="500em" />      
    </div>
  </v-row>
</template>

<script>
export default {
  name: "Login",

  data: () => ({}),
};
</script>

<style scoped>
#loginRow {
  height: 100%;
}

#test {
  bottom: 0px;
}

.login {
  text-align: center;
}
</style>
<template>
  <v-container  fluid fill-height>
    <Login v-if="user == null" />
    <template v-else>
      <Game v-once />
    </template>
  </v-container>
</template>

<script>
// @ is an alias to /src
import Login from "@/components/Login.vue";
import Game from "@/components/Game.vue";

export default {
  name: "Home",
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  components: {
    Login,
    Game,
  },
  mounted() {
    let currentHash = this.$router.history.current.hash;
    const parsedParams = {};
    currentHash
      .split("&")
      .map((part) => part.replace(/#/, ""))
      .forEach((param) => {
        const parts = param.split("=");
        parsedParams[parts[0]] = parts[1];
      });

    if (this.user == null) {
      if (parsedParams["access_token"] != undefined) {
        this.$store.dispatch("login", parsedParams["access_token"]);
      }
    } else {
      if (parsedParams["access_token"] != undefined) {
        this.$router.push("/");
      }
    }
  },
};
</script>

<template>
  <v-container>
    <v-row>
      <v-col><div id="twitch-embed-problematic"></div></v-col>
      <v-col><div id="twitch-embed-zaphire"></div></v-col>
    </v-row>      
  </v-container>
</template>

<script>
export default {
  name: "Home",
  mounted() {
    new Twitch.Embed("twitch-embed-problematic", {
       width: "100%",
       height: 480,
      channel: "problematiclive",
      // only needed if your site is also embedded on embed.example.com and othersite.example.com
      // parent: ["embed.example.com", "othersite.example.com"],
    });

    new Twitch.Embed("twitch-embed-zaphire", {
       width: "100%",
       height: 480,
      channel: "zaphireza",
      // only needed if your site is also embedded on embed.example.com and othersite.example.com
      // parent: ["embed.example.com", "othersite.example.com"],
    });
  },
};
</script>